import Vue from 'vue'

import store from '@/app/store';
import apiNavigation from '@/app/api/navigation.js';


export default new Vue({
  data() {
    return {
      tabs: [],

      activeTab: null,
      activeTabId: null,
      activeItem: null,

      // Keys for <router-view :key="xxxx">
      keys: {
        tab: null,
        menu: null
      },

      // Direccion en la que se ha "movido" el tab o el menu en el ultimo cambio de ruta
      // 1: adelante,  -1: atras,  0: no hay movimiento
      direction: {
        tab: 0,
        menu: 0
      },

      quickPaths: [],

      settings: {
        toolbar: {
          position: 'top'
        }
      }
    }
  },

  methods: {
    setToolbarPosition(pos) {
      this.settings.toolbar.position = pos == 'top' ? 'top' : 'bottom';
    },

    toggleToolbarPosition() {
      this.settings.toolbar.position = this.settings.toolbar.position == 'top' ? 'bottom' : 'top';
    },

    setThemeColor(color) {
      let metaThemeColor = document.querySelector('meta[name=theme-color]');
      metaThemeColor && metaThemeColor.setAttribute('content', color);
    },

    async hitRoute(route, from, next, router) {

      this.direction.tab = 0;
      this.direction.menu = 0;

      let tab = await this.getTabFromRoute(route, router);

      if (!tab) {
        this.activeTabId = null;
        this.keys.tab = route.path;
        this.keys.menu = null;

        next();
        return;
      }

      tab.currentTarget = route.path;

      // Tab changed
      if (tab.id != this.activeTabId) {

        // Mark current tab as "parent" of the new tab
        // when entering a non-fixed tab from a fixed tab
        if (!tab.fixed && this.activeTab && this.activeTab.fixed) {
          tab.parentId = this.activeTabId;
        } else {
          tab.parentId = null;
        }

        // Set direction (distance compared to current active index)
        let previousIndex = this.tabs.findIndex(t => t.id == this.activeTabId);
        let newIndex = this.tabs.findIndex(t => t.id == tab.id);
        this.direction.tab = newIndex - previousIndex;

        // Mark all others as inactive
        this.tabs.forEach(curTab => curTab.isActive = false);

        // Mark this as active
        this.activeTab = tab;
        this.activeTabId = tab.id;
        tab.isActive = true;

        // update view-router key
        this.keys.tab = tab.id;

        this.markActiveItems(route.path, tab.menu);

      } else if (tab.menu) { // Hit within tab.  Determine direction by looking at menu

        let previousIndex = from ? this.findMenuIndex(from.path, tab.menu) : -1;
        let newIndex = this.findMenuIndex(route.path, tab.menu);
        this.direction.menu = newIndex - previousIndex;

        // this.keys.menu = route.path;

        if (newIndex !== -1) {
          this.keys.menu = newIndex;
          this.markActiveItems(route.path, tab.menu);
        }
      }

      next();
    },

    getTabFromRoute(route, router) {
      let routeWithTab = route.matched.find(matchedRoute => !!matchedRoute.meta.tab);
      if (!routeWithTab) {
        return null;
      }

      let incomingTabId = (typeof routeWithTab.meta.tab == 'function') ? routeWithTab.meta.tab(route.params) : routeWithTab.meta.tab;
      if (!incomingTabId) {
        console.warn("Cannot determine navigation tab ID");
        return null;
      }

      if (routeWithTab.meta.local) {
        let localTab = Object.assign({ id: incomingTabId }, routeWithTab.meta);

        if (!localTab.menu) {
          // vue-router no incluye las rutas hijas ("children") en el objeto "route",
          // asi que toca extraerlas directamente de la reclaracion router.options.routes
          let declaration = this.findRouteDeclaration(routeWithTab.path, router.options.routes);
          localTab.menu = declaration ? this.buildMenu(declaration) : [];
        }

        return this.pushTab(localTab);
      }

      return this.fetchTab(incomingTabId);
    },

    async fetchTab(tabId) {
      let foundIndex = this.tabs.findIndex(t => t.id == tabId);
      if (foundIndex >= 0) {
        return this.tabs[foundIndex];
      }

      let placeholderTab = {
        isPlaceholder: true,
        fixed: true,

        id: tabId,
        text: '• • •',
        icon: 'mdi:timer-sand-full',
        isActive: false,
        currentTarget: ''
      };
      this.tabs.push(placeholderTab);

      let incomingTab;
      try {
        incomingTab = await apiNavigation(store.state.httpClient).getTab(tabId);
        if (!incomingTab) {
          throw "Server responded with an empty tab";
        }
      } catch (err) {
        this.tabs.splice(this.tabs.indexOf(placeholderTab), 1);
        console.warn("Cannot open tab", err);
        // return;
        throw err;
      }

      this.tabs.splice(this.tabs.indexOf(placeholderTab), 1);

      incomingTab.id = tabId;
      return this.pushTab(incomingTab);
    },

    pushTab(tab) {
      let foundIndex = this.tabs.findIndex(t => t.id == tab.id);
      if (foundIndex >= 0) {
        return this.tabs[foundIndex];
      }

      // Establecer propiedades predeterminadas
      let newTab = Object.assign({}, tab, {
        isActive: false,
        currentTarget: tab.menu && tab.menu.length ? tab.menu[0].target : null
      });

      this.tabs.push(newTab);

      return newTab;
    },

    closeTab(tab) {
      this.tabs.splice(this.tabs.findIndex(t => t.id == tab.id), 1);
    },


    /*
    Encontrar el indice en el que se encuentra "target" dentro del menu,
    contemplando submenus recursivamente.

    cosa1           index: 0
    cosa2           index: 1
      cosa2.1       index: 2
      cosa2.2       index: 3
        cosa2.2.1   index: 4
        cosa2.2.2   index: 5
      cosa2.3       index: 6
    */

    findMenuIndex(target, menu, offset = 0) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].target == target) {
          return i + offset;
        }

        if (menu[i].children) {
          let submenuIndex = this.findMenuIndex(target, menu[i].children, offset + i);
          if (submenuIndex >= 0) {
            return submenuIndex;
          }
          offset = offset + menu[i].children.length - 1;
        }
      }

      return -1;
    },

    findMenuItemByTarget(target, menu) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].target == target) {
          return menu[i];
        }

        if (menu[i].children) {
          let subItem = this.findMenuItemByTarget(target, menu[i].children);
          if (subItem) {
            return subItem;
          }
        }
      }

      return null;
    },

    markActiveItems(target, menu) {
      if (!menu || !menu.length) {
        return false;
      }

      let foundActive = false;

      for (let i = 0; i < menu.length; i++) {
        if (menu[i].target == target || (menu[i].children && this.markActiveItems(target, menu[i].children))) {
          menu[i].isActive = true;
          // Vue.set(menu[i], 'isActive', true);
          foundActive = true;

          if (menu[i].target == target) {
            this.activeItem = menu[i];
          }

        } else {
          menu[i].isActive = false;
          // Vue.set(menu[i], 'isActive', false);
        }
      }
      return foundActive;
    },

    buildMenu(routeDeclaration) {
      if (!routeDeclaration.children || !routeDeclaration.children.length) {
        return null;
      }

      let retval = [];
      routeDeclaration.children.forEach(child => {
        retval.push({
          target: child.path,
          text: child.meta && child.meta.text ? child.meta.text : child.path,
          icon: child.meta && child.meta.icon ? child.meta.icon : null,
          children: child.children ? this.buildMenu(child) : undefined
        });
      });

      return retval;
    },

    findRouteDeclaration(path, availableRoutes) {
      for (let i = 0; i < availableRoutes.length; i++) {
        if (availableRoutes[i].path == path) {
          return availableRoutes[i];
        }

        if (availableRoutes[i].children && availableRoutes[i].children.length) {
          let foundChild = this.findRouteDeclaration(path, availableRoutes[i].children);
          if (foundChild) {
            return foundChild;
          }
        }
      }

      return null;
    }
  }
})