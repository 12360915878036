/*
Este es un singleton para manejar notificaciones automaticamente en browser y/o cordova mediante un API sencillo
Basado en https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md

import notifications from '@/app/singletons/notifications';

notifications.subscribe()
  .then(device => {

    device:
      token,
      platform: window.device.platform == 'Android' ? 'gcm' : window.device.platform,
      model:    window.device.model,
      uuid:     window.device.uuid

    // post the token to the API (and/or save it to the store)
  });

notifications.onNotification(notification => {
  notification:
    type
    data
    native
});

notifications.onError(err => {});

notifications.unsubscribe();
*/

import serviceWokerNotifications from './handlers/serviceWorker.js';
import cordovaNotifications from './handlers/cordova.js';

export default {
  device: null,
  handler: null,

  listeners: {
    notification: [],
    error: []
  },

  subscribe() {
    return new Promise((resolve, reject) => {
      let isCordova = document.URL.indexOf("http://") === -1 && document.URL.indexOf("https://") === -1;
      if (isCordova) {
        document.addEventListener("deviceready", () => {
          this._subscribe(cordovaNotifications, resolve, reject);
        }, false);
      } else {
        this._subscribe(serviceWokerNotifications, resolve, reject);
      }
    });
  },

  _subscribe(handler, resolve, reject) {
    /* Set up listeners first, so that when a coldstart notification ocurrs (right during subscribe), the listeners are present */
    handler.onNotification(event => {
      this.listeners.notification.forEach(callback => callback(event));
    });

    handler.subscribe()
      .then(device => {
        this.handler = handler;
        this.device = device;
        resolve(device);
      })
      .catch(err => reject(err));
  },

  unsubscribe() {
    if (!this.handler) {
      return;
    }
    return this.handler.unsubscribe();
  },

  onNotification(callback) {
    this.listeners.notification.push(callback);
  },

  onError(callback) {
    this.listeners.error.push(callback);
  },

  dispatch(event, payload) {
    if (typeof this.listeners[event] == "undefined") {
      throw `Invalid event '${event}'`;
    }
    this.listeners[event].forEach(handler => handler(payload));
  }
};