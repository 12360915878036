export default httpClient => ({

  getInitialTabs() {
    return httpClient.get(`/1/app/navigation/start`)
  },

  getTab(tabId) {
    return httpClient.get(`/1/app/navigation/tabs/${tabId}`)
  }

});